@use 'sass:color';
@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.selectRoot {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  display: block;
}

.fileInputWrapper {
  position: relative;
  margin-bottom: 6px;
  text-align: center;
  background-color: color.adjust($extraLightTurquoise, $lightness: 7%);
  border: 2px dashed $regularCoolGray;
  border-radius: 5px;
  transition: background-color 0.1s linear;
  min-height: 80px;
  display: flex;
  align-items: center;

  &:focus-within {
    @include focusOutline;
  }

  &:not(.isUploading):hover,
  &:not(.isUploading):focus-within {
    background-color: $extraLightTurquoise;
  }
}

.orLabel {
  margin: 0;
  text-align: center;
}

.fileInputLabel {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 10px 10px;
  line-height: 20px;
  align-items: center;
}

.uploadFileInfoDescription {
  text-decoration: underline;
}

.uploadFileInfoDescriptionList {
  text-align: left;
  margin: 0;
  padding: 0;
  list-style-position: inside;
  color: $gray;
  font-size: 0.875rem;
}

.fileInput {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
