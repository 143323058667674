@use "sass:map";
@import '../../styles/variables';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

  @media (max-width: map.get($breakpoints, medium)) {
    position: fixed;
    top: calc(100% - 40px); /* 40px is compensating for header height */
    width: 100%;
    transition: top 0.3s;
    z-index: $z-index-pending-post-stream;

    &.open {
      position: fixed;
      top: 80px;
      width: 100%;
      height: calc(100% - 80px);

      &::before {
        position: fixed;
        content: '';
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  @media (min-width: map.get($breakpoints, medium)) {
    height: 100%;
    width: 25rem;
    flex-shrink: 0;
  }
}

.scrollListWrapper {
  background: $white;
  flex: 1;
  overflow: auto;
  position: relative;
}

.scrollList {
  height: 100%;
  padding: 10px;
  margin-right: 4px;
  overflow-x: visible;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.loader {
  display: block;
  margin: 20px auto 20px;
}

.loadMoreButtonWrapper {
  padding: 20px;
  padding-top: 0;
  text-align: center;
}

.visitorPostingDisabledMessage {
  background: $yellowExtraLight;
  padding: 10px 20px;
  border-bottom: 1px solid $yellowLight;
}

.filterbar {
  background-color: $white;
  padding: 10px 14px 0 10px;
  margin-bottom: -10px;
}
