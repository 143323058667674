@use "sass:math";
@import '../../styles/variables';

.root {
  position: relative;
  margin: 0 0 10px 0;
  background-color: $black;
  background-size: cover;
  overflow: hidden;
}

.pillarBox {
  margin-left: auto;
  margin-right: auto;
  width: math.percentage(math.div(9, 16));
  min-width: 10rem;
  max-width: 100%;
}

.blurredBackgroundImage {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(50px);
  background-size: cover;
  opacity: 0.4;
}

.figCaption {
  display: block;
  padding: 10px;
  background-color: $graySuperLight;

  .pinned & {
    background-color: $white;
  }
}
